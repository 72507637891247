@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kanit:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Asap:wght@300&family=Kanit:wght@400;500;600;700&display=swap");

///////Hamberger////////
.hamberger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  .burger {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: white;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }
}

////////////Nav////////////////////////

.navWraper {
  width: 100vw;
  height: 100px;
  position: fixed;
  z-index: 20;
  background: #252527;
  @media screen and (max-width: 420px) {
    height: 80px;
  }
  .navlinks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 15px;
    @media screen and (max-width: 769px) {
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0;
    }

    .logo {
      background: radial-gradient(#fff, rgb(204, 204, 204));
      border-radius: 50%;
      width: 70px;
      height: 70px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 420px) {
        height: 60px;
        width: 60px;
        margin-top: 10px;
      }
      img {
        width: 83px;
        height: 73px;
        // background-color: rgb(204, 204, 204, 0.8);
        @media screen and (max-width: 420px) {
          width: 63px;
          height: 63px;
        }
      }
    }
    .middleLinks {
      width: 30%;
      display: flex;
      justify-content: space-between;
      font-family: "Abril Fatface";
      color: white;

      .home,
      .plan,
      .photo,
      .contact {
        cursor: pointer;
        @media screen and (max-width: 768px) {
          display: none;
        }
        @media screen and (max-width: 920px) {
          font-size: 15px;
        }
      }
      @media screen and (max-width: 1350px) {
        width: 40%;
      }
      @media screen and (max-width: 920px) {
        width: 50%;
      }
      @media screen and (max-width: 768px) {
        width: 10%;
        // display: ;
      }
      .hambergerWrap {
        @media screen and (min-width: 769px) {
          display: none;
        }

        @media screen and (max-width: 768px) {
          display: fixed;
          padding-top: 10px;
          margin-left: 10px;
          z-index: 10;
        }
      }
    }
    .social {
      width: 5%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1000px) {
        width: 10%;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
      img {
        width: 30px;
      }
      .facebook {
        width: 23px;
      }
      .facebook,
      .igIcon {
        cursor: pointer;
      }
    }
  }
}
////Hamburger
.navModalWrap {
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  height: 100vh;
  // background: #cccccc; // white;
  background: #252527; // white;
  z-index: 10;
  color: white;
  // text-align: right;
  padding: 15px;
  // border-radius: 15px;
  .hamburgerlinks {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px;
    .home,
    .plan,
    .photo {
      font-family: "Abril Fatface";
      font-size: 28px;
      margin-top: 5px;
    }
  }
}

//////////////////////////////////////////////////////////////// REVIEW
.reviewWraper {
  width: 600px;
  height: 520px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    width: 400px;
    height: 420px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: 480px;
  }
  .upper {
    margin-bottom: 15px;
    img {
      width: 100px;
      height: 100%;
      // border-radius: 50%;
      object-fit: cover;
      @media screen and (max-width: 1000px) {
        width: 120px;
        height: 120px;
      }
    }
  }
  .reviewDetails {
    p {
      // color: white;
      //   width: 90%;
      @media screen and (max-width: 1000px) {
        font-size: 14px;
      }
    }
    h3 {
      // color: white;
      text-align: center;
      @media screen and (max-width: 1000px) {
        font-size: 16px;
      }
    }
  }
}

//////////////////////////////////////////////////////////////// Footer
.footerWrap {
  background-color: #252527;
  width: 100%;
  height: fit-content; // 150px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
  text-align: left;
  color: white;
  @media screen and (max-width: 1000px) {
    height: 350px;
  }
  @media screen and (max-width: 630px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
  .contact {
    width: 50%;
    display: flex;
    flex-direction: column;

    .contactsTitle {
      //   text-align: left;
      letter-spacing: 0.3em;
      font-family: "Abril Fatface";
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
    .content {
      display: flex;
      justify-content: space-around;
      @media screen and (max-width: 420px) {
        flex-direction: column;
      }

      .box3 {
        width: 265px;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    }
  }
  .map {
    img {
      width: 205px;
      height: 101px;
    }
  }
}

//////////////////// Price/////////////////
.pricePageWraper {
  width: 100vw;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding-top: 100px;
  background: #f2f2f2;
  text-align: center;
  @media screen and (max-width: 420px) {
    padding-top: 80px;
  }
  .header {
    font-family: "Asap", sans-serif;
    font-weight: 800;
    font-size: 62px;
    width: 100%;
    padding: 20px;
    // background: #97999c;
    @media screen and (max-width: 420px) {
      font-size: 42px;
    }
  }
  .promotion {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .special {
      // background: pink;
      width: 30%;
      // height: 70%;
      position: absolute;
      //
      // @media screen and (max-width: 1500px) {
      //   height: 50%;
      // }
      // @media screen and (max-width: 1300px) {
      //   height: 40%;
      // }
      right: 20%;
      .specialOffer {
        width: 100%;
        height: 100%;
        // padding: 50px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        h1 {
          background: white;
          color: black;
          width: 70%;
          margin: 0;
          font-size: 52px;
          padding: 10px;
          @media screen and (max-width: 1300px) {
            width: fit-content;
            font-size: 32px;
          }
          @media screen and (max-width: 820px) {
            font-size: 25px;
          }
          @media screen and (max-width: 420px) {
            font-size: 20px;
          }
          @media screen and (max-width: 375px) {
            font-size: 16px;
          }
        }
        h2 {
          font-size: 32px;
          color: #cad0d4;
          border: 6px solid #cad0d4;
          padding: 15px;
          @media screen and (max-width: 1300px) {
            font-size: 20px;
          }
          @media screen and (max-width: 820px) {
            font-size: 18px;
            padding: 5px;
            margin: 5px;
          }
          @media screen and (max-width: 420px) {
            font-size: 14px;
          }
        }
        .discountPrice {
          color: #ef7676;
          font-size: 78px;
          @media screen and (max-width: 1300px) {
            font-size: 30px;
          }
          @media screen and (max-width: 375px) {
            font-size: 22px;
          }
        }
        span {
          font-size: 29px;
          color: white;
          @media screen and (max-width: 1300px) {
            font-size: 18px;
          }
          @media screen and (max-width: 375px) {
            font-size: 13px;
          }
        }
        h3 {
          margin: 10px;
        }
        .oldPricewDash {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          h3 {
            margin: 3px;
          }
          .oldPrice {
            font-size: 50px;
            @media screen and (max-width: 1300px) {
              font-size: 20px;
            }
            @media screen and (max-width: 375px) {
              font-size: 13px;
            }
          }
          .dash {
            width: 150px;
            border: 3px solid #cad0d4;
            transform: rotate(-15deg);
            position: absolute;
            // top: 0;
            @media screen and (max-width: 420px) {
              width: 100px;
            }
            @media screen and (max-width: 375px) {
              width: 60px;
            }
          }
        }
        .validuser {
          color: white;
          font-weight: 500;
          @media screen and (max-width: 670px) {
            font-size: 10px;
          }
        }
      }
    }
    img {
      width: 60%;
      min-width: 370px;
      // width: 40%;
      // opacity: 0.8;
    }
  }

  ////////////////////////////////////////////////////////////////
  .boxWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // height: 60vh;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    // margin-top: 20px;
    @media screen and (max-width: 420px) {
      padding: 5px;
    }
  }
  // #97999c
  .contentBox {
    width: 267px;
    height: 339px;
    border: 1px solid white; // #9ba4b5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 15px;
    @media screen and (max-width: 420px) {
      width: 180px;
      height: 220px;
      margin: 10px;
    }
    .upper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-family: "Asap", sans-serif;
      .classType {
        //   font-family: "Aksara Bali Galang";

        font-size: 22px;
        line-height: 43px;
      }
      .price {
        font-size: 50px;
        line-height: 108px;
        font-weight: 800;
        @media screen and (max-width: 420px) {
          line-height: 90px;
        }
      }
      .dummyText {
        text-align: center;
        margin: 15px;

        @media screen and (max-width: 420px) {
          margin: 0;
        }
        p {
          color: red;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .bookBtn {
      width: 161px;
      height: 42px;
      border: 1px solid #cd1818;
      border-radius: 19.5px;
      color: #cd1818;
      background-color: white;
    }
  }
  .privateSessionHeader {
    width: 100%;
    // background: #97999c;
    color: black;
    margin: 0;
    padding: 30px;
    text-align: center;
  }
  .boxWrap.PriveteSession {
    // background: white; // #97999c;
    .contentBox {
      color: black;
      border: 1px solid #e75f32;
      .price {
        color: #e75f32;
      }
    }
  }

  .SemiPrivatex2Header {
    width: 100%;
    background: #2c2c2d;
    color: white;
    margin: 0;
    padding: 30px;
    text-align: center;
  }
  .boxWrap.SemiPrivatex2 {
    background: #2c2c2d;
    .contentBox {
      color: white;
      border: 1px solid #ef7676;
    }
  }
}
