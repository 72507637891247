@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kanit:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Asap:wght@300&family=Kanit:wght@400;500;600;700&family=Zen+Tokyo+Zoo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Kanit:wght@400;500;600;700&family=Raleway:wght@400;600;700;900&display=swap");
//font-family: 'Zen Tokyo Zoo', cursive; font-family: 'Abel', sans-serif;
.landingPageWraper {
  width: 100vw;
  height: 100vh;
  .main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .mainBg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.8;
      @media screen and (max-width: 769px) {
        height: 700px;
        // object-fit: contain;
        // opacity: 0.9;
      }
    }
    .displayWrap {
      position: absolute;
      height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      @media screen and (max-width: 430px) {
        height: 40%;
        padding: 0;
      }
      .title {
        font-family: "Abril Fatface";
        font-size: 82px;
        font-weight: 800;
        letter-spacing: 0.3em;
        color: white;
        text-align: center;
        // .moveTitile {
        //   color: rgb(205, 24, 24);
        //   font-size: 102px;
        //   @media screen and (max-width: 430px) {
        //     font-size: 75px;
        //   }
        //   // text-decoration: underline;
        // }
        // @media screen and (max-width: 1300px) {
        //   font-size: 62px;
        // }
        @media screen and (max-width: 1000px) {
          font-size: 72px;
          // text-align: center;
        }
        @media screen and (max-width: 430px) {
          font-size: 52px;
          // height: 100%;
          line-height: 155%;
        }
      }
      button {
        background-color: rgb(205, 24, 24, 0.8);
        // color: white;
        width: 150px;
        height: 55px;
        border: none;
        border-radius: 8px;
        a {
          text-decoration: none;
          color: white;
          font-weight: 500;
        }
      }
    }
  }
  .secondPart {
    position: relative;
    height: 750px;
    @media screen and (max-width: 966px) {
      height: 800px;
    }
    @media screen and (max-width: 671px) {
      height: 1006px;
    }
    @media screen and (max-width: 448px) {
      height: 900px;
    }
    @media screen and (max-width: 408px) {
      height: 780px;
    }
    @media screen and (max-width: 278px) {
      height: 1150px;
    }
    .boxWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -30px;
      .mainbox {
        width: 60%;
        min-width: 325px;
        height: 276px;
        // background-color: #323437;
        background-color: #252527;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 30px 20px 10px 20px;

        @media screen and (max-width: 965px) {
          height: 320px;
        }
        @media screen and (max-width: 660px) {
          height: 300px;
          padding: 15px 20px 10px 20px;
        }
        h1 {
          font-family: "Zen Tokyo Zoo", cursive;
        }
        p {
          // @media screen and (max-width: 420px) {
          //   display: none;
          // }
          margin: 0;
          font-size: 1em;
          font-family: sans-serif;
          @media screen and (max-width: 870px) {
            font-size: 0.9em;
          }
          @media screen and (max-width: 660px) {
            font-size: 0.8em;
          }
        }
        .p1,
        .p2 {
          text-indent: 2.5em;
        }
        h1 {
          color: rgba(205, 24, 24, 0.8);
        }
      }
      .smallBoxes {
        width: 725px;
        margin: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width: 790px) {
          width: 100%;
        }
        @media screen and (max-width: 420px) {
          width: 100%;
          justify-content: space-around;
        }
        .outerbox {
          border: solid 2px #cccccc;
          margin: 25px;
          border-radius: 4px;
          @media screen and (max-width: 420px) {
            margin: 10px;
          }
        }
        .outerbox:nth-child(2n) {
          border: solid 2px #323437;
          // background: #cccccc;
        }
        .box {
          width: 150px;
          height: 150px;
          // background-color: #323437;
          background-color: #252527;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          margin: 10px;
          border-radius: 4px;

          @media screen and (max-width: 448px) {
            margin: 15px;
            width: 120px;
            height: 120px;
          }
          @media screen and (max-width: 408px) {
            margin: 8px;
            width: 100px;
            height: 100px;
          }

          .checked {
            width: 19px;
            height: 19px;
            background-color: #202022;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          h3 {
            text-align: center;
            font-size: 20px;

            @media screen and (max-width: 420px) {
              font-size: 14px;
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .box:nth-child(2n) {
          background: #cccccc;
        }
      }
    }
  }

  .clientReview {
    height: fit-content;
    width: 100%;
    // background: #6f6d72;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // @media screen and (max-width: 420px) {
    //   .reviewWraper.reviewtwo {
    //     display: none;
    //   }
    // }
    .reviewLine {
      width: 60%;
      height: 5px;
      border-top: solid 2px black;
      border-bottom: dashed 2px black;
    }
  }
}
////////////////////////////////////////////////////////////////Gallery
.galleryWrapper {
  width: 100%;
  height: 88vh;
  padding: 20px;
}
.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  // justify-content: center;
  // padding: 0 4px;
  // padding: 0;
  height: fit-content;
  margin-left: 0;
  .column {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    max-width: 50%;
    padding: 0 4px;
    margin-top: 50px;

    @media screen and (max-width: 800px) {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 600px) {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
    img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
    }
  }
}

////////////////////////////////////////////////////////////////Contact us
///
.contactUs {
  width: 100vw;

  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  overflow: scroll;
  .header,
  .contexttext,
  .mapimg,
  .contact,
  .hours {
    margin-top: 20px;
  }

  .contactContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 120px;
    width: 80%;
    // height: 100%;

    @media screen and (max-width: 450px) {
      width: 100%;
    }

    .header {
      width: 45%;
      @media screen and (max-width: 450px) {
        width: 80%;
      }
      h1 {
        font-family: "Abril Fatface";
        font-size: 2em;
        text-align: left;
      }
    }
    .contexttext {
      // width: 45%;
      font-family: "Abel", sans-serif;
      font-size: 1.2em;
    }
    .mapimg {
      width: 50%;
      @media screen and (max-width: 450px) {
        width: 90%;
      }
      img {
        width: 100%;
      }
    }

    .contact {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 4px;
      @media screen and (max-width: 450px) {
        width: 80%;
      }
      .left {
        font-family: "Abel", sans-serif;
        font-size: 1.2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        p {
          margin: 0;
        }
      }
      .right {
        img {
          width: 120px;
          @media screen and (max-width: 450px) {
            width: 80px;
          }
        }
      }
    }
    .hours {
      .hour {
        font-size: 28px;
        @media screen and (max-width: 450px) {
          font-size: 24px;
        }
      }
      .reserve {
        text-align: center;
      }
    }
  }
}

////////////Price Page ////////////
///
.pricePageWraper {
  .promotion {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    img {
      margin-top: 15px;
    }
  }
}
